// src/Gallery.js

import React from "react"
import './Gallery.css';

export default class Gallery extends React.Component {

    componentDidMount() {
        var items = []
        , point = document.querySelector('svg').createSVGPoint();

        function getCoordinates(e, svg) {
        point.x = e.clientX;
        point.y = e.clientY;
        return point.matrixTransform(svg.getScreenCTM().inverse());
        }

        function changeColor(e) {
        document.body.className = e.currentTarget.className;
        }

        function Item(config) {
        Object.keys(config).forEach(function (item) {
        this[item] = config[item];
        }, this);
        this.el.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
        this.el.addEventListener('touchmove', this.touchMoveHandler.bind(this));
        }

        Item.prototype = {
        update: function update(c) {
        this.clip.setAttribute('cx', c.x);
        this.clip.setAttribute('cy', c.y);
        },
        mouseMoveHandler: function mouseMoveHandler(e) {
        this.update(getCoordinates(e, this.svg));
        },
        touchMoveHandler: function touchMoveHandler(e) {
        e.preventDefault();
        var touch = e.targetTouches[0];
        if (touch) return this.update(getCoordinates(touch, this.svg));
        }
        };

        [].slice.call(document.querySelectorAll('.item'), 0).forEach(function (item, index) {
        items.push(new Item({
        el: item,
        svg: item.querySelector('svg'),
        clip: document.querySelector('#clip-'+index+' circle'),
        }));
        });

        [].slice.call(document.querySelectorAll('button'), 0).forEach(function (button) {
        button.addEventListener('click', changeColor);
        });
    }

    render() {
        return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="c-profile">
                    <img src="/IMG_0324_small.png" className="c-profile__image float-left" alt="Wouter Rikkers"/>
                    <div className="c-profile__text float-left">Hi there!<br/>I'm Wouter Rikkers</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-11">
                    <div className="c-hero">
                    <div className="c-hero__text">
                        Digital Creative from Haarlem, The Netherlands.
                        <br/>
                        Specialized in functional interfaces & complex implementations.
                        <br/>
                        Currently Implementation Consultant & Front-end Developer at Eudonet STB.
                    </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-11">
                    <div className="c-go-to">
                    <a href="/" className="btn btn-primary">Back to CV</a>
                    </div>
                </div>
            </div>
            <div className="row c-gallery">
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.bhv.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-0'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                BHV.nl
                            </text>
                            <g clipPath='url(#clip-0)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/bhv.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                BHV.nl
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.bmwt.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-1'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                BMWT
                            </text>
                            <g clipPath='url(#clip-1)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/bmwt.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                BMWT
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.cov.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-2'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                COV
                            </text>
                            <g clipPath='url(#clip-2)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/cov.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                COV
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.crisicom.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-3'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Crisicom
                            </text>
                            <g clipPath='url(#clip-3)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/crisicom.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                Crisicom
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.denederlandseassociatie.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-4'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                De Nederlandse Associatie
                            </text>
                            <g clipPath='url(#clip-4)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/dna.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    De Nederlandse Associatie
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.kinderopvang.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-5'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Kinderopvang
                            </text>
                            <g clipPath='url(#clip-5)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/ko.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    Kinderopvang
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.netbeheernederland.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-6'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Netbeheer Nederland
                            </text>
                            <g clipPath='url(#clip-6)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nbnl.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    Netbeheer Nederland
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.nevedi.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-7'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Nevedi
                            </text>
                            <g clipPath='url(#clip-7)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nevedi.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                Nevedi
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.nrk.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-8'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                NRK
                            </text>
                            <g clipPath='url(#clip-8)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nrk.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    NRK
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.nvk.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-9'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                NVK
                            </text>
                            <g clipPath='url(#clip-9)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nvk.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    NVK
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.psychotherapie.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-10'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                NVP
                            </text>
                            <g clipPath='url(#clip-10)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nvp.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    NVP
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.heelkunde.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-11'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                NVvH
                            </text>
                            <g clipPath='url(#clip-11)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nvvh.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    NVvH
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.nzo.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-12'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                NZO
                            </text>
                            <g clipPath='url(#clip-12)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/nzo.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    NZO
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.pameijer.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-13'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Pameijer / Pawijzer
                            </text>
                            <g clipPath='url(#clip-13)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/pam.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    Pameijer / Pawijzer
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.plantum.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-14'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Plantum
                            </text>
                            <g clipPath='url(#clip-14)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/plantum.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    Plantum
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.rentokil.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-15'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                Rentokil
                            </text>
                            <g clipPath='url(#clip-15)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/rentokil.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    Rentokil
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.vaco.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-16'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                VACO
                            </text>
                            <g clipPath='url(#clip-16)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/vaco.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    VACO
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.vnci.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-17'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                VNCI
                            </text>
                            <g clipPath='url(#clip-17)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/vnci.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    VNCI
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.vrc.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-18'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                VRC
                            </text>
                            <g clipPath='url(#clip-18)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/vrc.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    VRC
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://hovo.vu.nl/nl/index.aspx" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-19'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                VU: Amsterdam
                            </text>
                            <g clipPath='url(#clip-19)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='/vu.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    VU: Amsterdam
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='item'>
                        <a href="https://www.zn.nl" target="_blank" rel="noopener noreferrer">
                            <svg preserveAspectRatio='xMidYMid slice' viewBox='0 0 300 200'>
                            <defs>
                                <clipPath id='clip-20'>
                                <circle cx='0' cy='0' fill='#000' r='150px'></circle>
                                </clipPath>
                            </defs>
                            <text className='svg-text' dy='.3em' x='50%' y='50%'>
                                ZN
                            </text>
                            <g clipPath='url(#clip-20)'>
                                <image height='100%' preserveAspectRatio='xMinYMin slice' width='100%' xlinkHref='zn.png'></image>
                                <text className='svg-masked-text' dy='.3em' x='50%' y='50%'>
                                    ZN
                                </text>
                            </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        )
    }
};