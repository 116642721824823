import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './Home.js';
import Gallery from './Gallery.js';

function App() {
  return (
    <BrowserRouter>

      <Route exact path="/" component={Home} />
      <Route exact path="/work" component={Gallery} />
    
    </BrowserRouter>
    
  );
}

export default App;
