// src/Home.js
 
import React from "react"
 
function Home() {
  return (
    <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="c-profile">
          <img src="/IMG_0324_small.png" className="c-profile__image float-left" alt="Wouter Rikkers"/>
          <div className="c-profile__text float-left">Hi there!<br/>I'm Wouter Rikkers</div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-1"></div>
      <div className="col-11">
        <div className="c-hero">
          <div className="c-hero__text">
            Digital Creative from Haarlem, The Netherlands.
            <br/>
            Specialized in functional interfaces & complex implementations.
            <br/>
            Currently Implementation Consultant & Front-end Developer at Eudonet STB.
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-1"></div>
      <div className="col-11">
        <div className="c-go-to">
          <a href="/work" className="btn btn-primary">Chekout my work & projects</a>
        </div>
      </div>
    </div>
    <div className="row">
        <div className="col-lg-1 col-md-12">
            <div className="c-vert-heading">
            <span className="c-vert-heading__title d-none d-lg-block">
                Experience
            </span>
            <span className="c-hor-heading__title d-lg-none">
                Experience
            </span>    
        </div>
      </div>
      <div className="col-11">
        <div className="c-experience">
          <div className="c-experience__list">
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">08/2016 - present</span>
                <span className="c-experience-list-item-left__function">Implementation Consultant & Front-end Developer</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Eudonet STB</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">04/2015 - present</span>
                <span className="c-experience-list-item-left__function">Owner</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Aduro Media</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">11/2015 - 07/2018</span>
                <span className="c-experience-list-item-left__function">Co-Founder / Design & Development</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Local Hero</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">07/2015 - 08/2016</span>
                <span className="c-experience-list-item-left__function">Front-end Developer</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">S2B Kennis en software voor verenigingen</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">05/2013 - 11/2014</span>
                <span className="c-experience-list-item-left__function">Full-stack Developer</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Pixelindustries</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">12/2011 - 04/2013</span>
                <span className="c-experience-list-item-left__function">Full-stack Developer</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Keytoe</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-1 col-md-12">
        <div className="c-vert-heading">
          <span className="c-vert-heading__title d-none d-lg-block">
            Education
          </span>
          <span className="c-hor-heading__title d-lg-none">
            Education
          </span>    
        </div>
      </div>
      <div className="col-11">
        <div className="c-experience">
          <div className="c-experience__list">
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">The Hague University of Applied Sciences</span>
                <span className="c-experience-list-item-left__function">Communication & Multimedia Design</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Den Haag</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">Grafisch Lyceum Rotterdam</span>
                <span className="c-experience-list-item-left__function">Multimedia Designer</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Rotterdam</span>
              </div>
            </div>
            <div className="c-experience-list__item">
              <div className="c-experience-list-item__left">
                <span className="c-experience-list-item-left__time">Grafisch Lyceum Eindhoven</span>
                <span className="c-experience-list-item-left__function">Graphic Designer</span>
              </div>
              <div className="c-experience-list-item__right">
                <span className="c-experience-list-item-right__company">Eindhoven</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-1 col-md-12">
        <div className="c-vert-heading">
          <span className="c-vert-heading__title d-none d-lg-block">
            Skills
          </span>
          <span className="c-hor-heading__title d-lg-none">
            Skills
          </span>    
        </div>
      </div>
      <div className="col-lg-5 col-md-12">
        <div className="c-devicons">
          <i className="devicon-angularjs-plain-wordmark"></i>
          <i className="devicon-apple-original"></i>
          <i className="devicon-bitbucket-plain-wordmark"></i>
          <i className="devicon-bootstrap-plain-wordmark"></i>
          <i className="devicon-bower-plain-wordmark"></i>
          <i className="devicon-cakephp-plain-wordmark"></i>
          <i className="devicon-confluence-plain-wordmark"></i>
          <i className="devicon-csharp-line-wordmark"></i>
          <i className="devicon-css3-plain-wordmark"></i>
          <i className="devicon-dot-net-plain-wordmark"></i>
          <i className="devicon-git-plain-wordmark"></i>
          <i className="devicon-github-plain-wordmark"></i>
          <i className="devicon-gitlab-plain-wordmark"></i>
          <i className="devicon-grunt-line-wordmark"></i>
          <i className="devicon-gulp-plain"></i>
          <i className="devicon-handlebars-plain-wordmark"></i>
          <i className="devicon-html5-plain-wordmark"></i>
          <i className="devicon-illustrator-plain"></i>
          <i className="devicon-javascript-plain"></i>
          <i className="devicon-jquery-plain-wordmark"></i>
          <i className="devicon-laravel-plain-wordmark"></i>
          <i className="devicon-less-plain-wordmark"></i>
          <i className="devicon-mysql-plain-wordmark"></i>
          <i className="devicon-nodejs-plain"></i>
          <i className="devicon-npm-original-wordmark"></i>
          <i className="devicon-photoshop-plain"></i>
          <i className="devicon-php-plain"></i>
          <i className="devicon-python-plain-wordmark"></i>
          <i className="devicon-react-original-wordmark"></i>
          <i className="devicon-sass-original"></i>
          <i className="devicon-trello-plain-wordmark"></i>
          <i className="devicon-typescript-plain"></i>
          <i className="devicon-visualstudio-plain"></i>
          <i className="devicon-vuejs-plain-wordmark"></i>
          <i className="devicon-windows8-original"></i>
        </div>
      </div>
      <div className="col-lg-1 col-md-12">
        <div className="c-vert-heading">
          <span className="c-vert-heading__title d-none d-lg-block">
            Contact
          </span>
          <span className="c-hor-heading__title d-lg-none mt-5">
            Contact
          </span>    
        </div>
      </div>
      <div className="col-lg-5 col-md-12">
        <div className="c-contact">
          <ul>
            <li><span className="float-left">Website</span><span className="float-right"><a href="http://www.wouterrikkers.nl" target="_blank" rel="noopener noreferrer">wouterrikkers.nl</a></span></li>
            <li><span className="float-left">Mail</span><span className="float-right"><a href="mailto:wouterrikkers@gmail.com">wouterrikkers@gmail.com</a></span></li>
            <li><span className="float-left">Phone</span><span className="float-right"><a href="tel:+31681286677">+31 (0)6 812 866 77</a></span></li>
            <li><span className="float-left">Linkedin</span><span className="float-right"><a href="https://www.linkedin.com/in/wouterrikkers/" target="_blank" rel="noopener noreferrer">/in/wouterrikkers</a></span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}
 
export default Home;